@use "../../variables/variables" as *;

.contact {
  .container {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  .contact-title {
    color: $text-skin;
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;

    @include mq("sm", "max") {
      font-size: 18px;
    }
  }

  .contact-sub-title {
    color: $text-black-900;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 60px;

    @include mq("sm", "max") {
      font-size: 12px;
    }
  }

  .contact-info-item {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 60px;

    @include mq("lg", "max") {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include mq("md", "max") {
      flex: 0 0 100%;
      max-width: 100%;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      color: $text-black-900;
      text-transform: capitalize;
      margin: 15px 0 5px;

      @include mq("sm", "max") {
        font-size: 14px;
        margin: 0;
      }
    }

    a {
      font-size: 16px;
      line-height: 25px;
      color: $text-black-700;
      font-weight: 400;

      @include mq("sm", "max") {
        font-size: 12px;
      }
    }

    .icon {
      display: inline-block;

      .react-icon {
        font-size: 30px;
        fill: $text-skin;

        &:hover {
          fill: $text-black-700;
          transform: scale(1.5);
        }
      }
    }
  }

  .contact-form {
    flex: 0 0 100%;
    max-width: 100%;

    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;

      @include mq("md", "max") {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
    }

    .form-item {
      margin-bottom: 30px;

      @include mq("sm", "max") {
        margin-bottom: 15px;
      }

      .form-control {
        width: 100%;
        height: 50px;
        border-radius: 25px;
        background: $bg-black-100;
        border: 1px solid $bg-black-50;
        padding: 10px 25px;
        font-size: 16px;
        color: $text-black-700;
        transition: all 0.3s ease;

        @include mq("sm", "max") {
          height: 30px;
          font-size: 12px;

          &::placeholder {
            font-size: 12px;
          }
        }

        &:focus {
          box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
        }
      }

      textarea.form-control {
        height: 140px;
      }
    }

    .btn {
      justify-content: center;
      background: none;
      height: 50px;
      padding: 0 50px;

      @include mq("sm", "max") {
        margin-top: 10px;
        height: 40px;
        font-size: 12px;
      }
    }
  }
}
