@import "src/variables/variables";

.aside {
  width: 270px;
  background: url(../../assets/images/bgpattern1.svg);
  background-color: $bg-black-100;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100vh;
  padding: 30px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid $bg-black-50;
  border-radius: 0 20px 20px 0;
  transition: all 0.4s ease-in-out;

  @include mq("xl", "max") {
    left: -270px;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 2rem;

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
      border-bottom: 1px solid $bg-black-50;
      font-weight: 600;
      color: $text-black-900;
      padding: 5px 15px;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.2);
        background-color: $text-skin;
        color: $text-black-700;
      }
    }
  }

  .logo {
    margin-top: 20px;
    flex: 0 1 30px;
    text-transform: capitalize;

    a {
      position: relative;
      color: $text-black-900;
      font-weight: 700;
      font-family: $font-dancing;
      padding: 15px 20px;
      font-size: 30px;
      letter-spacing: 5px;

      &:hover {
        transition: all 1s ease 0.75s;
        background: $text-black-900;
        color: $text-skin;
      }

      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border-top: 5px solid $text-skin;
        border-right: 5px solid $text-skin;
        top: 0;
        right: 0;
        transition: all 1s ease;
      }

      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border-bottom: 5px solid $text-skin;
        border-left: 5px solid $text-skin;
        bottom: 0;
        left: 0;
        transition: all 1s ease;
      }

      &:hover::after,
      &:hover::before {
        width: 100%;
        height: 100%;
      }
    }
  }

  .nav-list li a.active {
    color: $text-skin;

    &:hover {
      color: $text-black-700;
    }
  }
}

.aside.open {
  left: 0;
}

.nav-toggler {
  cursor: pointer;
  background: none;
  position: fixed;
  width: 30px;
  height: 30px;
  color: $text-black-700;
  top: 15px;
  border: 1px solid $text-black-900;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 0.4s ease-in-out;

  @include mq("xl", "max") {
    display: flex;
    left: 20px;
  }

  &:hover {
    color: $text-skin;
    border: 1px solid $text-skin;
  }
}

.nav-toggler.open {
  @include mq("xl", max) {
    left: 300px;
  }

  @include mq("lg", max) {
    left: 20px;
  }
}

.logo a span {
  font-family: $font-roboto;
}

.aside nav {
  flex: 1 0;
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}
